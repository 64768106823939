import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import parse from 'html-react-parser'
import Img from "gatsby-image"

import Video from "../components/Video/video"

import { Container, Row, Col } from 'react-bootstrap';

import { BioSection, TestimonialSection, ClientVenueSection, StyledH1, StyledH2 } from '../styles/hireChrisPageStyles';
import { isEmpty } from "lodash"

const HireChrisPage = ( { data } ) => {
  const { testimonials, video, clientVenues } = data.contentfulHireChris;
  const hirePicture = data.contentfulHireChris.picture.fluid;
  const writeUp = data.contentfulHireChris.writeUp.childMarkdownRemark.html; 
  const renderTestimonials = () => {
    return testimonials.map( testimonial => {
      if ( !isEmpty(testimonial.quote.childMarkdownRemark.html) && !isEmpty( testimonial.author ) ) {
        return (
          <div className="testimonials-container" key={testimonial.quote.childMarkdownRemark.id}>
            <div aria-hidden></div>
            {parse(testimonial.quote.childMarkdownRemark.html)}
            <p className="author">{testimonial.author}</p>
          </div>
        )
      } else if ( !isEmpty(testimonial.quote.childMarkdownRemark.html) ) {
        return (
          <div className="testimonials-container" key={testimonial.quote.childMarkdownRemark.id}>
            <div aria-hidden></div>
            {parse(testimonial.quote.childMarkdownRemark.html)}
          </div>
        )
      }
    } )
  }
  const renderClientVenues = () => {
    return clientVenues.map( clientVenueMedia => {
      console.log(clientVenueMedia);
      return (
        <Col xs={6} md={3} lg={2} className="d-flex align-items-center justify-content-center my-4 client-logo">
           <img src={clientVenueMedia.clientVenueImage.svg.dataURI} />
        </Col>
      )
    } )
  }
  
  return (
    <Layout>
      <SEO title="Hire Chris Page" />
      <BioSection>
        <Container>
          <Row>
            <Col xs={12}>
              <StyledH1>Hire Chris</StyledH1>
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={5}>
              <Img fluid={hirePicture} />
            </Col>
            <Col xs={12} lg={{ span: 6, offset: 1 }} className="d-flex flex-column justify-content-between">
              <div className="hire-text">
                {parse(writeUp)}
              </div>
              <Video
                videoSrcURL={video}
              />
            </Col>
          </Row>
        </Container>
      </BioSection>
      <TestimonialSection>
        <Container>
          <div className="testimonials">
            <StyledH2>Testimonials</StyledH2>
            {renderTestimonials()}
          </div>
        </Container>
      </TestimonialSection>
      <ClientVenueSection>
        <Container>
          <Row>
            <Col xs={12}>
              <StyledH2>Select Clients & Venues</StyledH2>
            </Col>
          </Row>
          <Row className="d-flex justify-content-center">
            {renderClientVenues()}
          </Row>
        </Container>
      </ClientVenueSection>
    </Layout>
  );
}

export default HireChrisPage;

export const pageQuery = graphql`
  query hireChrisPageQuery {
    contentfulHireChris {
      picture {
        fluid {
          aspectRatio
          sizes
          base64
          src
          srcSet
        }
      }
      writeUp {
        childMarkdownRemark {
          html
        }
      }
      video
      testimonials {
        quote {
          childMarkdownRemark {
            html
            id
          }
        }
        author
      }
      clientVenues {
        clientVenueImage {
          svg {
            absolutePath
            relativePath
            originalContent
            dataURI
            content
          }
        }
      }
    }
  }
`
