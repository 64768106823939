import styled from 'styled-components';

export const BioSection = styled.section`
  padding: 32px 0;

  @media (min-width: 992px) {
    padding: 48px 0;    
  }

  p {
    margin-top: 16px;
    color: ${({ theme }) => theme.colors.sapphireBlue};
  }

  p:first-child {
    @media (min-width: 992px) {
      margin-top: 0;  
    }
  }

  .hire-text {
    margin-top: 15px;
    @media (min-width: 992px) {
      margin-top: 0;
    }
  }

  .video {
    margin-top: 25px;
    @media (min-width: 992px) {
      margin-top: 50px;
    }
  }
`;

export const TestimonialSection = styled.section`
  padding: 32px 0;
  background-color: ${({ theme }) => theme.colors.sapphireBlue};
  color: ${({ theme }) => theme.colors.white};
  @media (min-width: 992px) {
    padding: 48px 0;    
  }

  p {
    color: ${({ theme }) => theme.colors.white};
  }

  .container {
    @media (min-width: 992px) {
      max-width: 50%;  
    }
  }

  .testimonials-container {
    position: relative;
    padding: 25px 0;

    @media (min-width: 992px) {
      padding: 50px 0;
    }

    .author {
      margin-top: 32px;
      text-align: right;
      font-style: italic;
    }
  }

  .testimonials-container:not(:first-of-type) {

    div::after {
      content: '';
      display: block;
      width: 50px;
      height: 2px;
      margin: 0 auto;
      background-color: ${({ theme }) => theme.colors.white};
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      top: 0;

      @media (min-width: 768px) {
         top: -2%
      }
    }
  }
`;

export const ClientVenueSection = styled.section`
  padding: 32px 0;
  @media (min-width: 992px) {
    padding: 48px 0;    
  }

  h2 {
    color: ${({ theme }) => theme.colors.sapphireBlue}; 
  }

  .client-logo {
    max-width: 200px;
    max-height: 200px;
    img {
      width: 100%;
      height: 100%;
    }
  }
`;

export const StyledH1 = styled.h1`
  color: ${({ theme }) => theme.colors.sapphireBlue};
  margin-bottom: 24px;

  @media (min-width: 992px) {
    margin-bottom: 48px;
  }
`;

export const StyledH2 = styled.h2`
  margin: 24px auto;
  text-align: center;

  @media (min-width: 992px) {
    margin: 32px auto;
    margin-bottom: 48px;
  }
`;
